export const articles = "vinco-news-articles";
export const courses = "courses";
export const universities = "universities";
export const courseTypes = "course-types";
export const freeCourses = "free-courses";
export const applications = "applications";
export const companies = "companies";
export const companyArticles = "company-articles";
export const userDetails = "user-details";
export const coaches = "coaches";
export const surveys = "surveys";
export const survey_questions = "survey-questions";
export const subjectAreaTags = "courses/subject-areas"
export const user_types = "user-types/general";
export const localizations = "localizations";
export const validatePhoneSignup = "pages/signup/phone-validation";
export const quick_applications = "quick-applications"
export const companyLogo = "companies-config"
export const dontShowUserTypes = [6, 7, 8];

import {
    getAllItems,
    getAllItemsWithoutToken,
    getItemById,
    getItemBySEOURL,
    getAllItemsWithQueryFromClient,
    getAllItemsWithQueryFromServerSide,
    getAllItemsCountWithQuery,
    getAllSavedItems,
    getCompanyArticlesById,
    redirectToIndex,
    getProtectedCompanyById,
    getItemByIdFromClientSide,
    createGetObjectAssign,
    getCollectionOfMultiplesGetItemByIdFromClientSide,
    getAllUserTypes,
    getLocalizationCurrency,
    updateLocalizationCurrency,
    getCoursesPageData,
    getCompanyLogoUrlByQuery,
    getCourseAllRequirements,
    getSuggestionsUrl,
    getCoursesBySEOUrlUniversity
} from './api';

// GET ALL COURSES
export async function getArticles(ctx) {
    return await getAllItems(ctx, articles);
}

export async function getLogoCompanyByqueryParams (id){
  if(!id) return null;

  let logo = null;
  const regex = /^[0-9]*$/;
  const onlyNumbers = regex.test(id);

  if(onlyNumbers){
    let response = await getCompanyLogoUrlByQuery(companyLogo,id)
    logo = response?.data ? response?.data : 'undefined'
  }
    return logo;
}

export async function getCompanies() {
    const res = await getAllItemsWithoutToken(companies);
    return res.items;
}

export async function getUserTypes() {
    const res = await getAllUserTypes(user_types);
    const result = res.items.filter((type) => !dontShowUserTypes.includes(type.id));
    return result;
}

export async function updateLocalization(currency) {
    return await updateLocalizationCurrency(localizations, currency);
}

export async function getLocalization() {
    return await getLocalizationCurrency(localizations);
}

export async function getSurveyById(id) {
    const survey = await getItemByIdFromClientSide(surveys, id);
    const surveyQuestions = survey['data']['survey_setups'].map(question => {
        return createGetObjectAssign(survey_questions, question.id);
    });
    const data = await getCollectionOfMultiplesGetItemByIdFromClientSide(surveyQuestions);

    // Order questions by survey setup
    let orderedSurvey = [];
    data.forEach((question, i) => {
        question.data.survey_setups.forEach((setup, j) => {
            if (setup.survey_owner === id) {
                orderedSurvey[question.data.survey_setups[j].order - 1] = question.data;
            }
        });
    });

    return orderedSurvey;
}

export async function getSurveyQuestions(id) {
    return await getAll(surveys, id);
}

export async function getCompanyById(ctx, id) {
    return await getProtectedCompanyById(ctx, companies, id);
}

export async function getCompany(ctx, id) {
    return await getItemById(ctx, companies, id);
}

export async function getCompanyArticles(ctx, id) {
    return await getCompanyArticlesById(ctx, companyArticles, id);
}

export async function getCourses(ctx) {
    return await getAllItems(ctx, courses);
}

export async function getCoursesByQueryFromClient(query) {
    return await getAllItemsWithQueryFromClient(query, courses);
}

export async function getCoursesByQueryFromServerSide(ctx, query) {
    return await getAllItemsWithQueryFromServerSide(ctx, query, courses);
}

export async function getCoursesCountWithQuery(query) {
    return await getAllItemsCountWithQuery(query, courses);
}

export async function getUniversities(ctx) {
    return await getAllItems(ctx, universities);
}

export async function getCourseTypes(ctx) {
    return await getAllItems(ctx, courseTypes);
}

export async function getSubjectAreaTags(ctx) {
    return await getAllItems(ctx, subjectAreaTags);
}

export async function getFreeCourses(ctx) {
    return await getAllItems(ctx, freeCourses);
}

///FIX
export async function getRecommenededCourses(ctx) {
    return await getCoursesPageData(ctx, recommendedCourses);
}

export async function getUniversity(ctx, seoUrl) {
    return await getItemBySEOURL(ctx, universities, seoUrl);
}

export async function getCourse(ctx, seoUrl) {
    return await getItemBySEOURL(ctx, courses, seoUrl);
}

export async function getFreeCourse(ctx, seoUrl) {
    return await getItemBySEOURL(ctx, freeCourses, seoUrl);
}

export async function getAllCoaches(ctx) {
    return await getCoaches(ctx, coaches);
}

export async function getSavedCourses() {
    return await getAllSavedItems(courses);
}

export async function redirectLogin(ctx) {
    return await redirectToIndex(ctx);
}

export async function getCourseRequirements(courseTypeId, universityId) {
    return await getCourseAllRequirements(courses, courseTypeId, universityId)
}

export async function getSuggestions(query) {
    return await getSuggestionsUrl(courses,query);
}

export async function getCoursesByUrlUniversity(query,ctx) {
    return await getCoursesBySEOUrlUniversity(courses,query,ctx);
}

