import Error from "next/error"
import jsSHA from "jssha";

export function hash(value)  {

  let result;
  
  try {

    const shaObj = new jsSHA('SHA-512', "TEXT", { encoding: "UTF8" });
    shaObj.update(value);
    result =  shaObj.getHash("HEX");

  } catch (e){
    Sentry.captureException('Error hashing password', e);
    throw new Error("Error hashing password");

  }
  return result;
}
