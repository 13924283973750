import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme as themeStyles } from "../styles/theme-constants";

import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const NEED_ONE_LOWERCASE = 'Una minúscula';
const NEED_ONE_UPPERCASE = 'Una mayúscula';
const NEED_ONE_NUMBER_OR_SPECIAL_CHAR = 'Un número o caracter especial';
const NEED_6_CHAR = 'Seis o más caracteres';

const passwordStrengthInitialState = {
  [NEED_6_CHAR]: true,
  [NEED_ONE_LOWERCASE]: true,
  [NEED_ONE_UPPERCASE]: true,
  [NEED_ONE_NUMBER_OR_SPECIAL_CHAR]: true,
};

export const useStyles = makeStyles((theme) => ({
  passwordStrength: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'wrap',
    backgroundColor: '#f6f6f6',
    padding: '0.8rem'
  },
  littleLabel: {
    fontSize: '0.9 rem',
    display: 'flex',
    alignItems: 'center',
  }
}));

export const PasswordStrengthRow = ({ password, setPasswordIsGood }) => {
  const classes = useStyles();

  const [passwordStrength, setPasswordStrength] = useState({ ...passwordStrengthInitialState });

  const hasOneNumber = new RegExp('(?=.*[0-9])');
  const hasOneLowercase = new RegExp('(?=.*[a-z])');
  const hasOneUppercase = new RegExp('(?=.*[A-Z])');
  const hasOneSpecialChar = new RegExp('(?=.*[*.!@#$%^&(){}:;<>,.?/~_+=|])');
  const hasAtLeastChar = new RegExp('.{6,32}');

  const checkPasswordStrength = (passwordString) => {

    setPasswordStrength({
      ...passwordStrength,
      [NEED_6_CHAR]: !hasAtLeastChar.test(passwordString),
      [NEED_ONE_LOWERCASE]: !hasOneLowercase.test(passwordString),
      [NEED_ONE_UPPERCASE]: !hasOneUppercase.test(passwordString),
      [NEED_ONE_NUMBER_OR_SPECIAL_CHAR]: !(hasOneNumber.test(passwordString) || hasOneSpecialChar.test(passwordString)),
    });
  }

  const resetPasswordStrength = () => {
    setPasswordStrength({ ...passwordStrengthInitialState })
  }

  useEffect(() => {
    !!password ? checkPasswordStrength(password) : resetPasswordStrength();
  }, [password]);

  useEffect(() => {
    if(setPasswordIsGood){
      setPasswordIsGood(Object.values(passwordStrength).every(value => value === false));
    }
  }, [passwordStrength]);

  return (
    <>
      {!!password ? (
        <div>
          <div className={classes.passwordStrength}>
            {
              Object?.keys(passwordStrength)?.map(key => {
                return (
                  <span  key={key?.toString()} className={classes.littleLabel} style={{ color: !passwordStrength[key] ? themeStyles.green : themeStyles.red }}>
                    {!passwordStrength[key] ? <CheckIcon fontSize={'small'} /> : <ClearIcon fontSize={'small'} />}
                    {key}
                  </span>
                )
              })
            }
          </div>
        </div>
      ) : null}
    </>
  )
}