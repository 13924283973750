const PROTOCOL = process.env.APP_PROTOCOL;
const APP_HOST_NAME = process.env.APP_HOST_NAME;
const APP_ENV = process.env.APP_ENV;

import {
  getJwtToken,
  getJwtTokenFromClient,
  getCurrentUserCookieFromServer
} from './cookies';
import axios from "axios";

// Get item without token
export async function getItemByIdFromClientSide(collection, id) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  )
    .then((response) => {
      return {
        data: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      // handleApiResponse(error.response);
      return error.response;
    });
}

// Get for object assign at getCollectionOfMultiplesGetItemByIdFromClientSide
export async function createGetObjectAssign(collection, id) {
  return axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  ).then((response) => {
    return {
      data: response.data,
      status: response.status
    };
  })
    .catch((error) => {
      // handleApiResponse(error.response);
      return error.response;
    });
};

// Get Collection of Items By Id
export async function getCollectionOfMultiplesGetItemByIdFromClientSide(requests) {
  return await axios.all(requests).then(axios.spread((...responses) => {
    return responses;
  })).catch(errors => {
    console.error(errors);
  });
}


// Get all items without token
export async function getAllItemsWithoutToken(collection) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/general`,
  )
    .then((response) => {
      return {
        items: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      // handleApiResponse(error.response);
      return error.response;
    });
}

// Get all items without token
export async function getAllUserTypes(collection) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}`,
  )
    .then((response) => {
      return {
        items: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      // handleApiResponse(error.response);
      return error.response;
    });
}

export const getSignupPageProps = async () => await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/companies/general`);

export const getSignupPagesCompaniesConfig = async (companyId = '') => await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/company-config/${companyId}`);

// Get all items with token
export async function getAllItems(ctx, collection) {
  
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtToken(ctx)}`
      }
    }
  )
    .then((response) => {
      return {
        items: response.data,
        currentUser: getCurrentUserCookieFromServer(ctx),
        status: response.status
      };
    })
    .catch((error) => {
      // handleApiResponse(error.response);
      return error.response;
    });
}

export async function getAllItemsWithQueryFromClient(query, collection) {
  const url = `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/get/all${query}`;
  return await axios.get(url, {
    headers: {
      'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
    }
  })
  .then((response) => {
    return {
      items: response.data,
      status: response.status
    };
  })
  .catch((error) => {
    return error.response;
  });
}

export async function getAllItemsWithQueryFromServerSide(ctx, query, collection) {
  const url = `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/reduced${query}`;
  return await axios.get(url, {
    headers: {
      'Authorization': `Bearer ${getJwtToken(ctx)}`
    }
  })
    .then((response) => {
      return {
        items: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getCompanyArticlesById(ctx, collection, id) {
  const url = `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}?article_company=${id}`;
  const response = await axios.get(url, {
    headers: {
      'Authorization': `Bearer ${getJwtToken(ctx)}`
    }
  });
  return { items: response.data, status: response.status };
}


export const redirectToIndex = (ctx) => {
  ctx.res.setHeader("location", "/login");
  ctx.res.statusCode = 302;
  ctx.res.end();
}

export async function getAllItemsCountWithQuery(query, collection) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/count${query ? query : ''}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  )
    .then((response) => {
      return {
        data: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      return error.response;
    });
}

// Get items saved courses
export async function getAllSavedItems(collection) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/reduced/`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  )
    .then((response) => {
      return {
        data: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      return error.response;
    });
}

// Get items - recommended courses
export async function getAllRecommendedCourses(collection) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/recommended`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  )
    .then((response) => {
      return {
        data: response.data,
        status: response.status
      };
    })
    .catch((error) => {
      return error.response;
    });
}

// Get item by id
export async function getItemById(ctx, collection, id) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtToken(ctx)}`
      }
    }
  )
    .then((response) => {
      return {
        item: response.data,
        currentUser: getCurrentUserCookieFromServer(ctx),
        status: response.status
      };
    })
    .catch((error) => {
      redirectFromErrorToIndex(ctx);
    });
}

// Get companies by Id (protected route using /general)
export async function getProtectedCompanyById(ctx, collection, id) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/general?id=${id}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtToken(ctx)}`
      }
    }
  )
    .then((response) => {
      return {
        item: response.data,
        currentUser: getCurrentUserCookieFromServer(ctx),
        status: response.status
      };
    })
    .catch((error) => {
      redirectFromErrorToIndex(ctx);
    });
}

// Temporal function
export async function getItemBySEOURL(ctx, collectionName, seoUrl) {
  return new Promise((resolve, reject) => {
    try {
      axios.get(
        `${PROTOCOL}://${APP_HOST_NAME}/api/${collectionName}?seo_url=${seoUrl}`,
        {
          headers: {
            'Authorization': `Bearer ${getJwtToken(ctx)}`
          }
        }
      )
        .then((response) => {
          if (response.data.length > 0) {
            resolve({
              item: { ...response.data[0] },
              currentUser: getCurrentUserCookieFromServer(ctx),
              status: response.status
            });
          } else {
            reject({ error: true });
          }
        })
        .catch((e) => {
          reject(e);
        });
    } catch (err) {
      reject(err);
    }
  })
}

// Get all coaches
export async function getCoaches(ctx, collection) {
  try {
    const response = await axios.get(
      `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}`,
      {
        headers: {
          'Authorization': `Bearer ${getJwtToken(ctx)}`
        }
      },

    );
    if (response.status === 200) {
      return {
        items: response.data,
        currentUser: getCurrentUserCookieFromServer(ctx),
        status: response.status
      };
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function sendEmailApi(message, typeEmail) {
  let response;
  const sendCoach = "email-to-coach";
  const sendSupport = "support"
  const route = `users-permissions/${typeEmail === "coach" ? sendCoach : sendSupport}`;
  const httpClient = axios.create();
  httpClient.defaults.timeout = 2000;
  response = await httpClient.post(`${PROTOCOL}://${APP_HOST_NAME}/api/${route}`, { message: message },
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
)
  return response;
}

export async function updateLocalizationCurrency(collection, currency) {
  return await axios.put(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/set`, { currency_shortform: currency },
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  )
  .then((response) => {
    return {
      data: response.data,
      status: response.status
    };
  })
  .catch((error) => {
    return error.response;
  });
}

export async function getLocalizationCurrency(collection) {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/${collection}`,
    {
      headers: {
        'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
      }
    }
  )
  .then((response) => {
    return {
      data: response.data,
      status: response.status
    };
  })
  .catch((error) => {
    return error.response;
  });
}

// TODO: How to redirect to login from 401
const handleApiResponse = (response) => {
  switch (response.status) {
    case 401:
      // TODO
      break;
    default:
      break;
  }
}

const redirectFromErrorToIndex = (ctx) => {
  ctx.res.setHeader("location", "/");
  ctx.res.statusCode = 302;
  ctx.res.end();
}

export const getCoursesPageData = async (ctx) => {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/coursespage`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => response.data).catch((e) => {
    // If error > remove cookies then go login
    removeCookies(ctx);
    ctx.res.setHeader("location", "/login");
    ctx.res.statusCode = 302;
    ctx.res.end();
  });
}

export const getHomePageData = async (ctx) => {
  const res = await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/pages/home/info`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  );
  return res.data;
};

export const getProfileData = async (ctx) => {
  const res = await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/pages/profile`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  );
  return res.data;
};

export const getCourseDetailPageData = async (ctx) => {

  // wtf, i dont know why but this url param is not the courseid, it's just called like that
  // its actually the seo_url value (from db)
  const seo_url = ctx.params?.courseid;
  const res = await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/pages/coursedetail/${seo_url}`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  );
  return res.data;

};

export const getQuickApplicationPageData = async (collection, ref) => {
  return await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/${ref}`);
}

export const createQuickApplication = async (collection, ref,form) => {
  return await axios.post(`${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/${ref}`,form)
}

export const existPhoneNumber = async (collection,validatePhone) => {
  return await axios.post(`${PROTOCOL}://${APP_HOST_NAME}/api/${collection}`,validatePhone)
}

export const getCourseAllRequirements = async (collection, courseTypeId, universityId) => {
  const response = await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/application/requirements?courseTypeId=${courseTypeId}&university=${universityId}`)
  return response?.data
}

export const getCompanyLogoUrlByQuery = async (collection, id) => await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/${id}`)

export const responseForm =  async (response, form) => {
  let url;
  if (APP_ENV === 'production') {
    const prod = {
      'question': "d/e/1FAIpQLScN9YQTERNohL6XW3Lt0ioAi55jf9k3A37uvJCbBAo3wDUP6w",
      'comment': "d/e/1FAIpQLSfpD5La10MicHZAuF9DDwnq0AbZCp1-qtKFe8Aq9CkuFazEuQ"
    }
    url = prod[form]
  } else {
    const dev = {
      'question': "d/1xhsziLrFZ08U6g9l-D_HOMF6-BQnNoZ6qxbSGzidsns",
      'comment': "d/1j_n62pVB2rIlaR0MskUcELKGoZJwBvnXErSZtlwRIvs"
    }
    url =  dev[form]
  }
  return await axios.post(`${PROTOCOL}://${APP_HOST_NAME}/api/google-forms`, {response, url})
}

export const getCourseFilters = async (ctx) => {
  return await axios.get(
    `${PROTOCOL}://${APP_HOST_NAME}/api/courses/filters/section`,
    {
      headers: {
        'AUTHORIZATION': `Bearer ${getJwtToken(ctx)}`
      }
    }
  ).then((response) => response.data).catch((e) => {
    // If error > remove cookies then go login
    removeCookies(ctx);
    ctx.res.setHeader("location", "/login");
    ctx.res.statusCode = 302;
    ctx.res.end();
  });
}

export const getSuggestionsUrl = async (collection, search) => {
  const response = await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/${collection}/search/suggestions?search=${search}`,{
    headers: {
      'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
    }
  })
  return response
}

export const getCoursesBySEOUrlUniversity = async (seo_url) => {
  const response = await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/courses/get/details?seo_url=${seo_url}`,{
    headers: {
      'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
    }
  })
  return response
}

export const getFavoriteCourses = async () => {
  const response = await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/user-details/get/favorite/courses`,{
    headers: {
      'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
    }
  })
  return response
}

export const getRecommendedCourses = async () => {
  const response = await axios.get(`${PROTOCOL}://${APP_HOST_NAME}/api/courses/recommended/section?page=1&limit=30`,{
    headers: {
      'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
    }
  })
  return response
}

export const getSimilarCourses = async ({tags,courseTypeId}) => {
  const data = {
    tagIds: tags,
    courseTypeId
  }
  const response = await axios.post(`${PROTOCOL}://${APP_HOST_NAME}/api/courses/similar/section?limit=20&page=1`,data,{
    headers: {
      'Authorization': `Bearer ${getJwtTokenFromClient().authToken}`
    }
  })
  return response
}